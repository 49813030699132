import bigpicture from 'bigpicture';
import { onAll, getAttribute, setStyle } from 'flightdom';

/**
 * Open lightbox for youtube videos on btns click
 * @param { NodeList } videoBtns
 */
function handleYoutubeBox(videoBtns) {
    onAll(videoBtns, 'click', (e) => {
        bigpicture({
            el: e.target,
            ytSrc: getAttribute(e.target, 'data-videoid')
        });
    });
}

/**
 * Get youtube video's cover image and add it to player (player is a styled div)
 * @param { NodeList } players
 */
function handleYoutubeEmbed(players) {
    players.forEach((el) => {
        const videoId = (getAttribute(el, 'data-videoid'));
        setStyle(el, 'background-image', `url(https://img.youtube.com/vi/${videoId}/sddefault.jpg)`);
    });
}

export { handleYoutubeBox, handleYoutubeEmbed };
