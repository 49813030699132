import {
    ajax,
    getText,
    setHTML,
    find,
    addClass,
    removeClass
} from 'flightdom';

/**
 * Send an ajax request to WordPress endpoint
 * @param { Object } params
 * @param { Function } errCb
 * @param { Function } successCb
 */
function wpAjax(params, errCb, successCb) {
    ajax('POST', '/wp/wp-admin/admin-ajax.php', params, errCb, successCb);
}

/**
 * Show a flash notification message
 * @param { String } type
 * @param { String } text
 * @param { Boolean } [translationPlaceholder]
 * @param { Array } [interpolations]
 */
function showFlashMessage(type, text, translationPlaceholder, interpolations) {
    const message = document.createElement('div');
    ['flash-message', 'closed', type].forEach(className => addClass(message, className));

    let diplayText;
    if (translationPlaceholder) {
        const translations = JSON.parse(getText(find('#js-translations')));
        diplayText = translations[text];

        if (interpolations) {
            interpolations.forEach((interpolation, i) => {
                diplayText = diplayText.replace(`%s${i}`, interpolation);
            });
        }
    }

    else diplayText = text;

    setHTML(message, `<p>${diplayText}</p>`);
    find('body').append(message);
    setTimeout(() => removeClass(message, 'closed'), 10);
    setTimeout(() => addClass(message, 'closed'), 5000);
}

function showNetworkErr() {
    showFlashMessage('error', 'unknown_error', true);
}

function showNotices() {
    wpAjax({ action: 'get_notices' }, showNetworkErr, (rawRes) => {
        const notices = JSON.parse(rawRes);
        if (notices.error && notices.error.length) {
            notices.error.forEach(error => showFlashMessage('error', error.notice));
        }

        if (notices.notice && notices.notice.length) {
            notices.notice.forEach(msg => showFlashMessage('success', msg.notice));
        }

        if (notices.success && notices.success.length) {
            notices.success.forEach(msg => showFlashMessage('success', msg.notice));
        }
    });
}

/**
 * Compute nbr of days between a given date and current time
 * @param { String } date
 * @returns { Number }
 */
function computeDaysFromNow(date) {
    return Math.floor((Date.parse(date) - Date.now()) / 86400000);
}

export {
    wpAjax,
    showFlashMessage,
    showNetworkErr,
    showNotices,
    computeDaysFromNow
};
