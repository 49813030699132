import {
    find,
    findAll,
    findChild,
    findChildren,
    ready,
    on,
    getParent,
    onAll,
    getValue,
    getAttribute,
    removeAttribute,
    getEventTarget,
    hasClass,
    addClass,
    removeClass,
    toggleClass,
    setHTML,
    setValue,
    debounce,
    navigateTo,
    getCookie,
    setCookie,
    preventDefault,
    callFnWithElementsIfExist,
    hasAttribute
} from 'flightdom';

import router from './router';
import { handleYoutubeBox, handleYoutubeEmbed } from './youtube';
import { showNetworkErr, showNotices, wpAjax } from './helpers';

/**
 * Saves user chosen lang in a cookie
 * @param { String } lang - 2 letter lang code
 */
function saveChosenLang(lang) {
    setCookie('chosenlang', `${lang};max-age=31536000;path=/;samesite=strict`);
}

/**
 * Pop in the language modal with proper settings
 * @param { String } matchingSiteLang
 * @param { HTMLElement } languageModal
 * @param { String } siteCurLang
 */
function showLanguageModal(matchingSiteLang, languageModal, siteCurLang) {
    const closeBtn = findChild(languageModal, 'button');

    if (matchingSiteLang === 'es' || matchingSiteLang === 'fr') {
        addClass(findChild(languageModal, `h1 .${matchingSiteLang}`), 'active');
    } else addClass(findChild(languageModal, 'h1 .en'), 'active');

    removeAttribute(languageModal, 'hidden');
    setTimeout(() => addClass(languageModal, 'active'), 1);

    on(closeBtn, 'click', () => {
        saveChosenLang(siteCurLang);
        removeClass(languageModal, 'active');
    });
}

/**
 * Normalise lang to lowercase 2 letter code
 * @param { String } slug
 * @returns { String }
 */
function normaliseLangSlug(slug) {
    return slug.substring(0, 2).toLowerCase();
}

/**
 * Find the preferred user lang among the avail. site langs
 * @param { HTMLLinkElement } alternates
 * @returns { String }
 */
function getPreferredSiteLang(alternates) {
    const siteLangList = Array.from(alternates).map((alternate) =>
        getAttribute(alternate, 'hreflang')
    );

    const preferredLanguages = navigator.languages.map((preferredLang) =>
        normaliseLangSlug(preferredLang)
    );

    // Get first preferred lang that matches an available lang
    return preferredLanguages.find((preferredLang) =>
        siteLangList.includes(preferredLang)
    );
}

/**
 * Display language modal if need to be and handle user choice
 * @param { HTMLElement } htmlTag
 * @param { HTMLLinkElement } alternates
 * @param { HTMLElement } languageModal
 * @param { HTMLLinkElement } headerLanguageLink
 * @param { NodeList } footerLanguageLinks
 * @returns
 */
function initLanguage(
    htmlTag,
    alternates,
    languageModal,
    headerLangLink,
    footerLanguageLinks
) {
    const chosenLang = getCookie('chosenlang');
    const languageLinks = findChildren(languageModal, 'a');
    const siteCurLang = getAttribute(htmlTag, 'lang').substring(0, 2);

    // Get first preferred lang that matches an available lang
    const matchingSiteLang = getPreferredSiteLang(alternates);

    onAll([...languageLinks, ...footerLanguageLinks], 'click', (e) => {
        preventDefault(e);
        const target = getEventTarget(e);
        saveChosenLang(normaliseLangSlug(getAttribute(target, 'hreflang')));
        navigateTo(getAttribute(target, 'href'));
    });

    // Open modal when header lang is clicked
    on(headerLangLink, 'click', () =>
        showLanguageModal(matchingSiteLang, languageModal, languageModal)
    );

    // Automatically show selection modal as user hasn't chosen his lang yet
    if (chosenLang === siteCurLang) return;
    showLanguageModal(matchingSiteLang, languageModal, siteCurLang);
}

/**
 * Oversea menu behaviours
 * @param { HTMLElement } header
 * @param { NodeList } menu
 * @param { NodeList } menuParents
 * @param { HTMLElement } toggleMobile
 */
function initMenu(header, menu, menuParents, toggleMobile) {
    let pageloaded = false;
    let active = null;
    let calledClose = null;

    const closeMenu = () => {
        if (!active) return;
        removeClass(active, 'active');
        removeClass(header, 'unfolded');
        active = null;
    };

    const debouncedCloseMenu = debounce(closeMenu, 500);

    onAll(menuParents, 'mouseover', (e) => {
        // Prevents from opening the fat menu if just click-navigate parent
        if (!pageloaded || hasClass(toggleMobile, 'active')) return;

        clearTimeout(calledClose);
        if (active === e.currentTarget) return;

        closeMenu();
        active = e.currentTarget;
        addClass(e.currentTarget, 'active');
        addClass(header, 'unfolded');
    });

    onAll(menu, 'mouseout', () => {
        if (active) calledClose = debouncedCloseMenu();
    });

    setTimeout(() => {
        pageloaded = true;
    }, 500);
}

/**
 * Manage show/hide mobile menu
 * @param { HTMLButtonElement } toggleMenuBtn
 * @param { HTMLElement } menu
 */
function initMobileMenu(toggleMenuBtn, menu) {
    let mobileOn = false;
    let inSubMenu = false;

    // Flat menu on user acount, so default to empty array
    const parents = menu ? findChildren(menu, '.menu-item-has-children') : [];

    on(toggleMenuBtn, 'click', () => {
        toggleClass(toggleMenuBtn, 'active');
        toggleClass(menu, 'active');
        toggleClass(document.body, 'noscroll');
        mobileOn = !mobileOn;
        inSubMenu = false;

        parents.forEach((el) => {
            removeClass(el, 'active');
            const activeHeader = findChild(el, '.active');
            if (activeHeader) removeClass(activeHeader, 'active');
        });
    });

    onAll(parents, 'click', (e) => {
        if (!mobileOn) return;
        const target = e.target;
        const parent = getParent(target);

        if (
            !hasClass(parent, 'menu-item-has-children') &&
            !hasClass(parent, 'menu-item-has-grandchildren')
        )
            return;

        e.preventDefault();

        // Add .active on parent li & parent link
        if (!inSubMenu) {
            inSubMenu = true;
            addClass(e.currentTarget, 'active');
            setTimeout(() => addClass(target, 'active'), 100);
        } else if (hasAttribute(target, 'href')) {
            inSubMenu = false;
            removeClass(target, 'active');
            removeClass(getParent(target), 'active');
        }
    });
}

/**
 * Search the global search
 * @param { HTMLFormElement } searchForm
 * @param { HTMLInputElement } searchbar
 * @param { HTMLElement } results
 * @param { HTMLBodyElement } body
 */
function initSearch(searchForm, searchbar, results, body) {
    let search;
    let isSearchActive = false;

    const debouncedSearch = debounce(() => {
        if (!search) {
            setHTML(results, '');
            return;
        }

        wpAjax(
            { action: 'get_search_results', search },
            showNetworkErr,
            (res) => {
                if (res) setHTML(results, res);
                else {
                    const noMatchText = getAttribute(results, 'data-no-match');
                    setHTML(
                        results,
                        `<li class="no-result">${noMatchText}</li>`
                    );
                }
            }
        );
    }, 300);

    on(searchbar, 'input', (e) => {
        isSearchActive = true;
        search = getValue(e.target);
        debouncedSearch();
    });

    // Mouse goes over results, on leave, reset search and unfocus searchbar
    on(body, 'click', (e) => {
        if (hasClass(e.target, 'searchinput') || !isSearchActive) return;
        setHTML(results, '');
        setValue(searchbar, '');
        document.activeElement.blur();
        isSearchActive = false;
    });

    on(searchForm, 'submit', preventDefault);
}

/**
 * Hide first level entries on mobile if more than 4
 * hidden class is applied and CSS dictates if hidden (mobile) or not
 * @param { NodeList }
 */
function initMobileBreadcrumb(breadcrumb) {
    if (breadcrumb.length < 4) return;

    for (let index = 0; index + 3 < breadcrumb.length; index++) {
        addClass(breadcrumb[index], 'hidden');
    }
}

ready(() => {
    // Load conditional modules
    router({
        home: 'home',
        'header-description': [
            'tax-product_cat',
            'tax-product_tag',
            'post-type-archive-product'
        ],
        filters: [
            'tax-product_cat',
            'tax-product_tag',
            'post-type-archive-product'
        ],
        faq: [
            'referral-page',
            'loyalty-page',
            'tax-product_cat',
            'tax-product_tag'
        ], // cart has its own logic for FAQ as DOM is often overwritten
        cart: 'woocommerce-cart',
        checkout: 'woocommerce-checkout',
        contact: 'contact',
        account: 'woocommerce-account',
        referral: ['referral-page', 'woocommerce-account'],
        'single-product': 'single-product',
        'help-center': ['help-center', 'contact']
    }).catch(console.error);

    initMenu(
        find('.main-header'),
        findAll('.header-menu'),
        findAll('.header-menu .menu-item-has-children'),
        find('#toggle-menu')
    );

    if (hasClass(document.body, 'woocommerce-account'))
        initMobileMenu(
            find('#toggle-menu'),
            find('.woocommerce-MyAccount-navigation')
        );
    else initMobileMenu(find('#toggle-menu'), find('.header-menu ul'));

    callFnWithElementsIfExist(initLanguage, [
        find('html'),
        findAll('link[rel="alternate"]'),
        find('#language-modal'),
        find('#lang-selector'),
        findAll('.footer .lang-item')
    ]);

    initSearch(
        find('#searchform'),
        find('#searchform input'),
        find('#searchform .search-results'),
        find('body')
    );

    handleYoutubeEmbed(findAll('.youtube.player'));
    callFnWithElementsIfExist(handleYoutubeBox, [findAll('.youtube')]);
    callFnWithElementsIfExist(initMobileBreadcrumb, [
        findAll('.breadcrumb-item')
    ]);
    showNotices();
});
